@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", serif;
  margin: 0;
  padding: 0;
}
* :hover {
  transition: all 0.2s ease-in-out;
}
#root{
  @apply w-full min-h-screen h-full flex flex-col justify-between
}
.active {
  @apply underline underline-offset-8;
}
h2::first-letter,
h3::first-letter,
h4::first-letter {
  text-transform: uppercase;
}
.menu-icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  width: 100%;
  height: 2px;
  background-color: black;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.open .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.open .line:nth-child(2) {
  opacity: 0;
}

.open .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}
